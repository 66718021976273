import React, { isValidElement, cloneElement } from 'react';

function modifyValidChilds(child, isTargetCB, newPropsCB) {
  if (Array.isArray(child)) {
    return React.Children.map(child, (subChild) =>
      modifyValidChilds(subChild, isTargetCB, newPropsCB));
  }
  if (isValidElement(child) && isTargetCB(child)) {
    return cloneElement(child, newPropsCB(child));
  }
  if (child && child.props && child.props.children) {
    return cloneElement(
      child,
      null,
      modifyValidChilds(child.props.children, isTargetCB, newPropsCB)
    );
  }
  return child;
}

export function recursiveModifyChildren(children, isTargetCB, newPropsCB) {
  return modifyValidChilds(children, isTargetCB, newPropsCB);
}
