import React, { useEffect } from 'react';

import catalog from 'assets/pdf/vdm-catalog.pdf';
import { loadPdfViewer, previewPdf } from 'utils/pdf';

import {
  Root, PdfContainer
} from './styles';

function Catalog() {
  useEffect(() => {
    const loadPdf = async () => {
      const viewer = await loadPdfViewer('pdf-container');
      previewPdf(viewer, catalog, 'Catálogo-VDM.pdf');
    };

    loadPdf().then();
  }, []);

  return (
    <Root>
      <PdfContainer id="pdf-container" />
    </Root>
  );
}

Catalog.propTypes = {
};

Catalog.defaultProps = {
};

export default Catalog;
