import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
`;

export const Center = styled.div`  
  margin: 0 auto;
  
  @media (max-width: 1247px) {
    width: 100%;
  }
`;

export const CarouselSection = styled.section`
  width: 100%;
  
  a {
    text-decoration: none;
    font-weight: bold;
    color: #fff;
  }
`;

export const CarouselImg = styled.img`
  width: 100%;
  height: 450px;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.7);

  @media (max-width: 1247px) {
    height: 400px;
  }

  @media (max-width: 1090px) {
    height: 350px;
  }

  @media (max-width: 390px) {
    height: 300px;
  }
`;

export const CarouselContent = styled.div`
  width: 100%;
  height: 100%;
  //background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(88,92,97,0.3) 45%, rgba(125,125,125,0.3) 100%);
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CarouselText = styled.div`
  width: 80%;
  margin-left: 15%;
  text-shadow: 0px 0px 3px var(--text-color);
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 540px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.span`
  font-size: 1.5rem;

  @media (max-width: 540px) {
    font-size: 1.2rem;
  }
`;

export const ProductLineSection = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4rem 0;
`;

export const SectionTitle = styled.span`
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);  
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  text-align: center;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 0.5rem;
    width: 120px;
    overflow-wrap: anywhere;
    text-align: center;
   
    :hover {
      opacity: 0.5;
    }

    @media (max-width: 640px) {
      width: 100px;
    }
  }
`;

export const ProductIcon = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  margin-bottom: 1rem;
  align-items: center;

  svg {
    color: #fff;
    font-size: 3rem;
  }

  @media (max-width: 640px) {
    width: 4rem;
    height: 4rem;
    svg {
      font-size: 2.5rem;
    }
  }
`;

export const ProductName = styled.p`
  margin-bottom: 1rem;
  color: var(--text-color);  
  display: flex;
  justify-content: center;
  font-weight: bold;

  @media (max-width: 640px) {
    font-size: 0.8rem;    
  }
`;

export const MapBase = styled.div`
  width: 100%;
  height: 350px;
  `;

export const Map = styled.iframe.attrs(() => ({
  src: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15100.30763300318!2d-48.287883!3d-18.883669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3619b8888862f46e!2sVDM%20Equipamentos%20de%20Seguran%C3%A7a%20e%20Uniformes%20LTDA!5e0!3m2!1spt-BR!2sbr!4v1613696399953!5m2!1spt-BR!2sbr',
  title: 'vdm-map',
  frameBorder: '0',
  allowullscreen: '',
}))`
  width: 100%;
  height: 100%;
`;

export const LineBase = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: var(--text-color) 0px 0px 3px 1px;
`;

export const Separate = styled.div`
  height: 2rem;
`;

export const MapSection = styled.section`
  width: 100%;
`;
