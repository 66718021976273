import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop';
import Routes from 'routes';
import Products from 'utils/products';

function App() {
  const [products, setProducts] = useState([]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Products setProducts={setProducts} />
      <Routes products={products} />
    </BrowserRouter>
  );
}

export default App;
