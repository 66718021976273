import styled from 'styled-components';

export const Button = styled.button`
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.2rem;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  background-color: ${(props) => props.disabled ? 'var(--terciary-color)' : 'var(--primary-color)'};
  color: ${(props) => props.disabled ? 'var(--text-color-light)' : '#fff'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};

  :hover {
    background-color: ${(props) => props.disabled ? 'var(--terciary-color)' : 'var(--primary-color-light)'};
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  }
`;
