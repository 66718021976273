import { Button } from 'components/Button/styles';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  margin: 1rem 0;
  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

export const CarouselBase = styled.div`
  min-width: 42rem;
  max-width: 42rem;
  padding: 1rem;

  @media (max-width: 1190px) {
    min-width: 38rem;
    max-width: 42rem;
  }

  @media (max-width: 1090px) {
    min-width: 34rem;
    max-width: 38rem;
  }

  @media (max-width: 990px) {
    min-width: 30rem;
    max-width: 34rem;
  }

  @media (max-width: 890px) {
    min-width: 26rem;
    max-width: 30rem;
  }

  @media (max-width: 790px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const DetailContainer = styled.div`
  height: 22rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 790px) {
    width: 100%;
    height: auto;
  }
`;

export const CarouselImg = styled.img`
  width: 100%;
  height: 20rem;
  object-fit: contain;
  
  @media (max-width: 490px) {
    height: 14rem;
  }
`;

export const Detail = styled.div`
  margin-left: 0.5rem;  
  font-size: 1rem;

  @media (max-width: 1247px) {
    font-size: 0.9rem;
  }
`;

export const DetOne = styled.div`
  font-size: 1.1em;
  padding: 0 0 1em;
  font-weight: bold;

  a {
    text-decoration: none;
    color: var(--text-color);

    :hover {
      color: var(--text-color-light);
    }
  }
`;

export const DetTwo = styled.div`
  font-size: 1em;
  padding: 0 0 1em;
`;

export const DetThree = styled.div`
  font-size: 0.9em;
  padding: 0 0 1em;
`;

export const DetFour = styled.div`
  font-size: 0.9em;
  padding: 0 0 1em;
  color: var(--text-color-light);
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid var(--terciary-color);
  padding: 1rem 1rem 2rem 1rem;

  @media (max-width: 1247px) {
    font-size: 0.8rem;
  }
`;

export const TitleDescription = styled.h4`
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 1rem;

  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

export const Cart = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 690px) {
    justify-content: center;
  }
`;

export const CartButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);

  :hover {
    background-color: var(--secondary-color-light);
  }

  svg {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
`;

export const DescriptionName = styled.span`
  @media (max-width: 590px) {
    text-align: center;
  }
`;

export const LongDescription = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  white-space: pre-line;
`;

export const Descriptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductDescriptions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  border-bottom: 0.1rem solid var(--terciary-color-light);

  & > :first-child {
    font-weight: bold;
  }

  & > * {
    width: 50%;
  }

  :last-child {
    border-bottom: none;
  }

  @media (max-width: 590px) {
    flex-direction: column;
    justify-content: center;    

    & > * {
      width: 100%;
      padding: 0.5rem;
    }
  }
`;

export const Especifications = styled.div`
  border: 0.1rem solid var(--terciary-color-light);
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const Description = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  & > * {
    width: 50%;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 590px) {  
    justify-content: center;

    & > * {
      width: unset;
      padding: 0 0.2rem;
    }
  }
`;

export const Variations = styled.div`
  display: flex;
  flex-direction: column;
  width: 15rem;

  @media (max-width: 1247px){
    font-size: 0.9rem;
  }
`;
