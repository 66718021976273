import epiBackground from 'assets/images/epi-background-about-us.jpg';
import styled from 'styled-components';

export const BackgroundPage = styled.img.attrs(() => ({
  src: epiBackground,
  alt: 'background'
}))`
  object-fit: cover;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  padding: 5rem;
  
  @media (max-width: 1134px) {
    padding: 5rem 2rem;
    top: 5.7rem;
  }
  
  @media (max-width: 740px) {
    padding: 3rem 0rem;
  }
  
  @media (max-width: 535px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    position: unset;
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  border-radius: 0.2rem;
  background-color: rgb(71, 141, 209, 0.5);
    
  @media (max-width: 360px) {
    padding: 0.5rem;
    margin-top: 0;
  }
`;

export const AboutUsTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
  padding-bottom: 2rem;
    
  @media (max-width: 360px) {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-weight: bold;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: #fff;

  :last-child {
    padding-bottom: 0;
  }

  @media (max-width: 612px) {
    font-size: 1rem;
    padding: 0.5rem;
  }
`;

export const Title = styled.h4`
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;

  @media (max-width: 360px) {
    text-align: center;
  }
`;

export const OurDifferentials = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 0.2rem;
  background-color: var(--primary-color);
  box-shadow: 0.5rem 0.5rem 0.5rem 0.2rem rgba(0, 0, 0, 0.5);
  
  @media (max-width: 360px) {
    padding: 0.4rem 1.4rem;
    box-shadow: none;
  }
  `;

export const Ul = styled.ul`
  padding: 1rem 0 0 1rem;
  
  @media (max-width: 360px) {
    padding: 0
  }
`;

export const Li = styled.li`
  list-style: disc;
  font-weight: bold;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 0;
    
  @media (max-width: 612px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;
