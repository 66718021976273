import React from 'react';
import { ImLocation2, ImPhone } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { RiPagesFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import {
  Root, BottonInfo, Tabs, Tab, FooterLink, Text,
  SubText, ContactContent
}
  from 'components/Footer/styles';
import { navMap } from 'components/Header';
import { Logo, LogoImg } from 'components/Header/styles';
import SocialMedia from 'components/SocialMedia';

function Footer() {
  return (
    <Root>
      <Tabs>
        <Tab>
          <Logo>
            <Link to="/">
              <LogoImg />
            </Link>
          </Logo>
          <SocialMedia />
        </Tab>
        <Tab>
          <Text>
            <RiPagesFill />
            Páginas
          </Text>
          {navMap.map((nav) => (
            <FooterLink key={nav.to} to={nav.to}>
              {nav.name}
            </FooterLink>
          ))}
        </Tab>
        <Tab>
          <ContactContent>
            <Text>
              <ImLocation2 />
              Endereço
            </Text>
            <SubText>
              Av. Cleanto Vieira Gonçalves, 1342
              <br />
              Pacaembú, Uberlândia - MG, 38401-545
            </SubText>
          </ContactContent>
          <ContactContent>
            <Text>
              <ImPhone />
              Telefone
            </Text>
            <SubText><a href="tel:+553432171212">(34) 3217 - 1212</a></SubText>
          </ContactContent>
          <ContactContent>
            <Text>
              <MdEmail />
              E-mail
            </Text>
            <SubText><a href="mailto:licitacao.vdm@vdmepi.com.br">licitacao.vdm@vdmepi.com.br</a></SubText>
          </ContactContent>
        </Tab>
      </Tabs>
      <BottonInfo>
        VDM Equipamentos de Segurança e Uniformes
      </BottonInfo>
    </Root>
  );
}

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default Footer;
