import { Button } from 'components/Button/styles';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  @media (max-width: 690px) {
    padding: 1rem 0.5rem;
  }
`;

export const Center = styled.div`  
  width: var(--master-content-width);
  margin: 0 auto;

  @media (max-width: 1247px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  padding: 1rem 1.5rem;
  background-color: #fff;

  @media (max-width: 360px) {
    padding: 1rem 0.7rem;
  }
`;

export const CardHeader = styled.h1`
  font-size: 1.8rem;
  color: var(--text-color);
  margin: 0 0 1rem 0; 

  @media (max-width: 360px) {
    text-align: center;
  }
`;

export const ButtonBase = styled.div`
  margin: 0.5rem 0 0 0;
  display: flex;
  justify-content: flex-end;
`;

export const ProductDetail = styled.div`
  width: 35%;
  display: flex;
  align-items: center;

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 515px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

export const ProductImg = styled.img`
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  padding: 1rem;
`;

export const MoreProductsCart = styled(Button)`
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);

  :hover {
    background-color: var(--secondary-color-light);
  }

  svg {
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  @media (max-width: 360px) {
    padding: 0.7rem;
  }

  @media (max-width: 310px) {
    font-size: 0.8rem;
  }
`;

export const ProductContainer = styled.div`
  & > div {
    :nth-last-child(2) {
      border-bottom: none;
    }
  }
`;

export const SelectedProducts = styled.h2`
  width: 100%;
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
`;

export const ProductContent = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid var(--terciary-color);

  & > div {
    :nth-last-child(2) {
      border-bottom: none;
    }
  }

  @media (max-width: 515px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ProductCountAndRemove = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 515px) {
    justify-content: center;
    width: 100%;
    margin-bottom: 0.8rem;
  }
`;

export const ProductCount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid var(--text-color-light);
  border-radius: 0.2rem;
  width: 6rem;
  height: 2.5rem;

  @media (max-width: 515px) {

  }

  @media (max-width: 540px) {
    margin-left: 1rem;
  }
`;

export const ProductCountButton = styled.button`
  width: 100%;
  cursor: pointer;
  color:  ${({ disabled }) => disabled ? 'var(--secondary-color-light)' : 'var(--secondary-color)'};
`;

export const MoreProduct = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 515px) {
    justify-content: center;
  }
`;

export const InputProductCount = styled.input`
  width: 100%;
  text-align: center;
  border: none;

  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

export const RemoveProduct = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--secondary-color);

  :hover {
    color: var(--secondary-color-light);
  }
`;

export const Message = styled.p`
  color: ${(props) => props.success ? '#48d147' : 'red'};
  font-size: 1rem;  
  font-weight: bold;
  margin: 0 1rem;
  text-align: center;
  display: flex;
  align-items: center;
`;
