import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SlideContainer = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;

  & >  a:last-child {
    right: 0;

    :hover{
      box-shadow: -1rem 0 1.8rem 1.2rem rgba(255 255 255 / 30%);
    }
  }
`;

export const Slide = styled.div`
  display: block;
  position: ${(props) => props.isActive ? 'unset' : 'absolute'};

  // width: ${(props) => props.isActive ? '100%' : '0'};
  opacity: ${(props) => props.isActive ? 1 : 0};
  transition: opacity 1s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

export const NavButton = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  color: #fff;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0 0.5rem;

  transition: 0.3s ease;
  user-select: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 1rem 0 1.8rem 1.2rem rgba(255 255 255 / 30%);
    color: black;
  }

  @media (max-width: 690px) {
    font-size: 1rem;

    :hover {
      box-shadow: 1rem 0 1rem 1.2rem rgba(255 255 255 / 30%);
    }
  }
`;

export const DotsBase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding: 1rem;
`;

export const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) => props.isActive ? '#fff' : '#000'};
  box-shadow: ${(props) => props.isActive ? '0 0 0.2rem 0.2rem rgba(0 0 0 / 30%)' : '0 0 0.2rem 0.2rem rgba(255 255 255 / 30%)'} ;
  margin: 0 0.5rem;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 690px) {
    width: 0.8rem;
    height: 0.8rem;
  }
`;

export const ThumbnailsBase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    padding: 1rem 0;
  }
`;

export const Thumbnail = styled.div`
  margin: 0 0.5rem;

  & > img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.2rem;
    box-shadow: ${(props) => props.isActive ? '0 0 0.2rem 0.2rem rgba(0 0 0 / 70%)' : 'none'};
  }

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }


  @media (max-width: 640px) {
    margin: 0.5rem;
  }
`;

export const ImageModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50vw;
    height: auto;
    max-height: 70vh;
    background-color: #ffffff;
    cursor: ${(props) => props.zoom ? 'zoom-out' : 'zoom-in'};
    transform: ${(props) => props.zoom ? 'scale3d(1.5, 1.5, 1)' : 'none'};
    -webkit-transform: ${(props) => props.zoom ? 'scale3d(1.5, 1.5, 1)' : 'none'};

    @media (max-width: 1200px) {
      width: 65vw;
    }

    @media (max-width: 640px) {
      width: 100vw;
    }
  }
`;
