import styled from 'styled-components';

export const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderBase = styled.header`
  width: 100%;
  background-color: var(--primary-color);
  box-shadow: var(--text-color-light) 0px 1px 5px 0px;
  z-index: 998;
  position: sticky;
  top: 0;
`;

export const FooterBase = styled.footer`
  width: 100%;
  background-color: var(--secondary-color);
  box-shadow: var(--text-color-light) 0px -1px 5px 0px;
  z-index: 997;
`;

export const CenterBase = styled.div`
  width: 100%;
  flex: 1;
`;
