export function getLocalProductsList() {
  return getItem('productsList', true);
}

export function saveLocalProductsList(productsList) {
  setItem('productsList', productsList, true);
}

export function getLocalLastFilterUrl() {
  return getItem('lastFilterUrl');
}

export function saveLocalLastFilterUrl(lastFilterUrl) {
  setItem('lastFilterUrl', lastFilterUrl);
}

export function getLocalProducts() {
  return getItem('products', true);
}

export function saveLocalProduct(product) {
  const products = getLocalProducts() || {};
  products[getUniqueProductKey(product)] = { qtd: 1, ...product };
  setItem('products', products, true);
}

export function qtdUpdateLocalProduct(product, qtd) {
  const products = getLocalProducts();
  if (products[getUniqueProductKey(product)]) {
    products[getUniqueProductKey(product)].qtd = qtd;
  }
  setItem('products', products, true);
}

export function removeLocalProduct(product) {
  const products = getLocalProducts() || {};
  if (products[getUniqueProductKey(product)]) {
    delete products[getUniqueProductKey(product)];
  }
  setItem('products', products, true);
}

export function removeAllLocalProducts() {
  setItem('products', undefined, true);
}

function getItem(item, isObject) {
  const foundItem = localStorage.getItem(item);
  const newFoundItem = isObject ? JSON.parse(foundItem) : foundItem;
  if (!isValidStorageDate(newFoundItem)) {
    removeItem(item);
    return undefined;
  }
  return newFoundItem;
}

function setItem(item, value, isObject) {
  let newValue = value;
  if (isObject) {
    newValue = newValue || {};
    newValue.storageDate = new Date();
  }
  localStorage.setItem(item, isObject ? JSON.stringify(newValue) : newValue);
}

function removeItem(item) {
  localStorage.removeItem(item);
}

function isValidStorageDate(item) {
  if (item && item.storageDate) {
    const diff = new Date() - new Date(item.storageDate);
    if (diff > (1000 * 60 * 60 * 12)) {
      return false;
    }
  }
  return true;
}

function getUniqueProductKey(product) {
  let variants;
  Object.entries(product.selectedVariations || {}).map(([key, value]) => {
    variants = `${key}-${value}`;
    return variants;
  });
  return `${product.id}-${variants}`;
}
