export const loadPdfViewer = async (divId) =>
  new Promise((resolve) => {
    if (window.AdobeDC) {
      resolve(new window.AdobeDC.View({ clientId: getKeyByHost(), divId }));
    } else {
      document.addEventListener('adobe_dc_view_sdk.ready', () =>
        resolve(new window.AdobeDC.View({ clientId: getKeyByHost(), divId })));
    }
  });

export const previewPdf = (viewer, pdf, fileName) => {
  viewer.previewFile({
    content: { location: { url: pdf } },
    metaData: { fileName }
  }, { showAnnotationTools: false });
};

function getKeyByHost() {
  const keys = process.env.REACT_APP_ADOBE_PDF_KEY.split(';');
  const servers = process.env.REACT_APP_SERVER_BASE_PATH.split(';');
  if (keys && keys.length > 1 && servers && servers.length > 1) {
    const foundedHostIndex = servers.findIndex((s) => s === window.location.origin);
    return keys[foundedHostIndex] || process.env.REACT_APP_ADOBE_PDF_KEY;
  }
  return process.env.REACT_APP_ADOBE_PDF_KEY;
}
