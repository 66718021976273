export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const newString = removeMask(phone);
  return newString && newString.length >= 10 && newString.length <= 11;
}

export function removeMask(value) {
  return value && value
    .split('(')
    .join('')

    .split(')')
    .join('')

    .split(' ')
    .join('')

    .split('-')
    .join('')

    .split('.')
    .join('')

    .split('/')
    .join('')

    .split('_')
    .join('');
}
