export function similarity(s1, s2) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
  // eslint-disable-next-line no-param-reassign
  s1 = s1.toLowerCase();
  // eslint-disable-next-line no-param-reassign
  s2 = s2.toLowerCase();

  // eslint-disable-next-line no-array-constructor
  const costs = new Array();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j;
      } else if (j > 0) {
        let newValue = costs[j - 1];
        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }
  return costs[s2.length];
}

export function cleanUpSpecialChars(str) {
  return str
    .replace(/[ÁÀÂÃ]/g, 'A')
    .replace(/[áàâã]/g, 'a')
    .replace(/[ÉÈÊ]/g, 'E')
    .replace(/[éèê]/g, 'e')
    .replace(/[íï]/g, 'I')
    .replace(/[íï]/g, 'i')
    .replace(/[ÓÔÕÖ]/g, 'O')
    .replace(/[óôõö]/g, 'o')
    .replace(/[Ú]/g, 'U')
    .replace(/[ú]/g, 'u')
    .replace(/[Ç]/g, 'C')
    .replace(/[ç]/g, 'c')
    .replace(/[Ñ]/g, 'N')
    .replace(/[ñ]/g, 'n')
    .replace(/[ ]/g, '');
}

export function getNameByPath(path) {
  const removeExtension = path.substring(0, path.lastIndexOf('.'));
  const extension = removeExtension.split('/');
  const lastIndex = extension[extension.length - 1];
  return lastIndex;
}

const wordsIgnoreCase = [
  'de', 'da', 'dos', 'das', 'para', 'em', 'com'
];

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    if (!wordsIgnoreCase.includes(splitStr[i])) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
  }
  return splitStr.join(' ');
}
