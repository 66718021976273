import Select from 'react-select';

import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 1rem 0;

  :focus-within {
    label {
      color: var(--text-color);
    }
  }
`;

export const InputStyle = styled(Select)` 
  color: var(--text-color);
`;

export const Label = styled.label`
  font-size: 0.9rem;
  margin: 0 0 0.3rem 0;
  color: var(--text-color-light);

  @media (max-width: 1247px) {
    font-size: 0.8rem;
  }
`;
