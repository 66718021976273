import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 1rem 0;

  :focus-within {
    input {
      border-bottom: 0.1rem solid var(--text-color);
    }

    label {
      color: var(--text-color);
    }
  }
`;

export const InputTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  color: var(--text-color); 
  font-size: 1.1rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 0.1rem solid var(--text-color-light);
  padding: 0.2rem 1rem;
  resize: none;

  @media (max-width: 1247px) {
    font-size: 1rem;
  }
`;

export const Label = styled.label`
  font-size: 0.9rem;
  margin: 0 0 0.3rem 0;
  color: var(--text-color-light);

  @media (max-width: 1247px) {
    font-size: 0.8rem;
  }
`;
