import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  user-select: none;
  height: 3rem;

  @media (max-width: 890px) {
    height: 2.5rem;
  }
`;

export const InputText = styled.input.attrs(() => ({
  type: 'text'
}))`
  display: block;
  width: 100%;
  color: var(--text-color-light);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  font-size: 1.1rem;
  padding: 0 4rem 0 1.1rem;
  border-radius: 0.2rem;
  background-color: #fff;
  border: 0;

  @media (max-width: 890px) {
    font-size: 1rem;
    padding: 0 3.5rem 0 0.9rem;
  }
`;

export const ButtonBase = styled.div` 
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -3.5rem;

  @media (max-width: 890px) {
    margin-left: -2.9rem;
  }
`;

export const Button = styled.button`
  height: 100%;
  padding: 0 1rem; 
  font-size: 1.4rem;
  background-color: #fff;
  border: 0;
  outline: none;
  border-radius: 0.2rem;
  color: var(--text-color-light);
  cursor: pointer;

  :hover {
    color: var(--text-color);
    background-color:rgba(0, 0, 0, 0.02);
  }

  @media (max-width: 890px) {
    font-size: 1.2rem;
    padding: 0 0.8rem;
  }
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid var(--terciary-color);
  height: 2rem;

  @media (max-width: 890px) {
    height: 1.5rem;   
  }
`;
