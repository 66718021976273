import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  margin: 1rem;

  @media (max-width: 690px) {
    padding-top: 0;
    margin: 1rem 0 0 0;
  }
`;

export const Filters = styled.div`
  @media (max-width: 690px) {
    display: none;
  }
`;

export const MobileFilters = styled.div`
  display: none;
  width: fit-content;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 690px) {
    display: inherit;
  }
`;

export const Category = styled.div`
  font-size: 1.1rem;

  @media (max-width: 890px) {
    font-size: 1rem;
  }
`;

export const CategoryList = styled.ul`
  padding: 0.5rem 0;
`;

export const CategoryItem = styled.li`
  font-size: 1.1rem;
  padding: 0 0 0.5rem 0.8rem;

  svg {
    margin-right: 0.5rem;
  }

  a {
    display: flex;
    color: var(--text-color-light);
    text-decoration: none;

    :hover {
      color: var(--text-color);
    }
  } 

  @media (max-width: 890px) {
    font-size: 1rem;
  }
`;

export const ModalTitle = styled.div`
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--text-color-light);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
      margin-left: 0.5rem;

      :hover {
        color: var(--text-color-light);
      }
    }
`;

export const ModalContent = styled.div`
`;
