import React, { cloneElement, useEffect, useState } from 'react';

import BreadCrumbs from 'components/BreadCrumbs';
import Search from 'components/Search';
import SideBar from 'components/SideBar';
import LandingPage from 'layouts/LandingPage';
import { StoreBase, Page, SideBarBase, Content } from 'layouts/StoreLandingPage/styles';
import PropTypes from 'prop-types';
import { createBreadCrumbsFilters, createBreadCrumbsProduct, routeFiltersParse } from 'utils/filters';
import { getLocalLastFilterUrl } from 'utils/localStorage';

function StoreLandingPage({ children, ...props }) {
  const { match, history } = props;
  const routeFilters = match && match.params && match.params.routeFilter ?
    decodeURIComponent(match.params.routeFilter) : undefined;
  const productId = match && match.params ? match.params.id : undefined;

  const hideFilters = children && children.props && children.props.hideFilters ?
    children.props.hideFilters : false;

  const [filters, setFilters] = useState({});
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (routeFilters) {
      const newFilters = routeFiltersParse(routeFilters);
      const newCrumbs = createBreadCrumbsFilters(newFilters);
      setFilters(newFilters);
      setBreadCrumbs(newCrumbs);
    }
  }, [routeFilters]);

  useEffect(() => {
    if (productId) {
      const newCrumbs = createBreadCrumbsProduct(productId, getLocalLastFilterUrl());
      setBreadCrumbs(newCrumbs);
    }
  }, [productId]);

  return (
    <LandingPage {...props}>
      <StoreBase>
        <Search history={history} />
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <Page hideFilters={hideFilters}>
          <SideBarBase hideFilters={hideFilters}>
            <SideBar
              filters={filters}
              routeFilters={routeFilters}
              hideFilters={hideFilters}
            />
          </SideBarBase>
          <Content>
            {cloneElement(children, { ...props, filters })}
          </Content>
        </Page>
      </StoreBase>
    </LandingPage>
  );
}

StoreLandingPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      routeFilter: PropTypes.string,
    }),
  }),
  history: PropTypes.objectOf(PropTypes.any),
};

StoreLandingPage.defaultProps = {
  children: undefined,
  match: undefined,
  history: undefined
};

export default StoreLandingPage;
