import { Button } from 'components/Button/styles';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-left: 1px solid var(--terciary-color);

  @media (max-width: 690px) {
    border-left: unset;
  }
`;

export const ProductsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 490px) {
    display: none;
  }
`;

export const ProductsGridMobile = styled.div`
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 490px) {
    display: flex;
  }
`;

export const ProductCard = styled.div`
  width: 12.59rem;
  margin: 0.5rem;
  border-radius: 0.2rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  cursor: pointer;

  :hover {
    box-shadow: 0 1px 10px 5px rgb(0 0 0 / 20%);
  }

  @media (max-width: 890px) {
    width: 11rem;
  }

  @media (max-width: 490px) {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  height: 9rem;
  border-bottom: 1px solid var(--terciary-color);

  @media (max-width: 890px) {
    height: 8rem;
  }
`;

export const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.2rem 0.2rem 0 0;
  object-fit: contain;
  padding: 1rem;
`;

export const CardDescription = styled.div`
  padding: 0.5rem 1rem 1.5rem 1rem;
  font-size: 1.1rem;

  @media (max-width: 890px) {
    font-size: 0.9rem;
  }
`;

export const DescOne = styled.div`
  font-size: 1em;
  padding: 0 0 1em;
  text-align: center;
  font-weight: bold;
`;

export const DescTwo = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 0.9em;
  padding: 0 0 1em;
`;

export const Span = styled.span`
  font-size: 0.7rem;
  margin-right: 0.3rem;
  color: var(--text-color-light);
`;

export const DescThree = styled.div`
  font-size: 0.8em;
  padding: 0 0 1em;
`;

export const DescFour = styled.div`
  font-size: 0.8em;
`;

export const Pagination = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media (max-width: 490px) {
    display: none;
  }
`;

export const PageButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  font-weight: ${(props) => props.isActive ? 'bold' : 'normal'};
  color: ${(props) => props.isActive ? 'var(--text-color)' : 'var(--text-color-light)'};
  background-color: ${(props) => props.isActive ? 'rgba(0, 0, 0, 0.1)' : '#fff'};

  :hover {
    cursor: ${(props) => props.isActive ? 'default' : 'pointer'};;
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 890px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

export const PaginationMobile = styled.div`
  margin-top: 1.5rem;
  display: none;
  justify-content: center;
  align-items: baseline;

  @media (max-width: 490px) {
    display: flex;
  }
`;

export const PageButtonMobile = styled(Button)`
  font-size: 0.9rem;
  color: var(--text-color-light);
  background-color: #fff;

  :hover {
    cursor: ${(props) => props.isActive ? 'default' : 'pointer'};;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
