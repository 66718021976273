import React from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'components/Carousel';
import PropTypes from 'prop-types';
import { getProductLines, getProductSectors } from 'utils/products';

import {
  Root, CarouselSection, ProductLineSection, MapBase,
  Map, CarouselImg, Center, SectionTitle, SectionContent,
  ProductIcon, ProductName, MapSection, CarouselText,
  Title, Subtitle, CarouselContent
} from './styles';

function Home({ routeFilters }) {
  return (
    <Root>
      <CarouselSection>
        <Carousel arrows dots slideShowDelay={5000}>
          {getProductSectors().map((s) => (
            <Link key={`${s.alt}-${s.src}`} to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${s.value}` : s.value)}`}>
              <CarouselImg src={s.src} alt={s.alt} />
              <CarouselContent>
                <CarouselText>
                  <Title>
                    {s.label}
                  </Title>
                  <Subtitle>
                    {s.subtitle}
                  </Subtitle>
                </CarouselText>
              </CarouselContent>
            </Link>
          ))}
        </Carousel>
      </CarouselSection>

      <ProductLineSection>
        <Center>
          <SectionTitle>
            LINHAS DE PRODUTOS
          </SectionTitle>
          <SectionContent>
            {getProductLines().map((pl) => (
              <Link key={pl.value} to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${pl.value}` : pl.value)}`}>
                <ProductIcon>
                  {pl.icon}
                </ProductIcon>
                <ProductName>
                  {pl.label}
                </ProductName>
              </Link>
            ))}
          </SectionContent>
        </Center>
      </ProductLineSection>

      <MapSection>
        <SectionTitle>
          VENHA NOS CONHECER
        </SectionTitle>
        <MapBase>
          <Map />
        </MapBase>
      </MapSection>
    </Root>
  );
}

Home.propTypes = {
  routeFilters: PropTypes.string
};

Home.defaultProps = {
  routeFilters: undefined
};

export default Home;
