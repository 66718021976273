export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js').then(() => {
        // console.log(registration);
      }).catch(() => {
        // console.log(err);
      });
    });
  }
};
