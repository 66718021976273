import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { getProductImgUrl } from 'services/products';
import { sortArray } from 'utils/array';
import { getLocalProductsList, saveLocalLastFilterUrl } from 'utils/localStorage';
import { findByCategory, findByProductLine, findBySector, findBySubCategory } from 'utils/products';
import { similarity } from 'utils/string';
import { getUrlQueryParam } from 'utils/url';

import {
  Root, ProductsGrid, ProductsGridMobile, ProductCard, CardImage,
  ProductImg, CardDescription, Pagination, PageButton,
  PaginationMobile, PageButtonMobile, DescOne, DescFour, Span
} from './styles';

const productsPerPage = 15;
const maxPaginationPages = 6;

function Products({ history, location, filters }) {
  const page = Number(getUrlQueryParam(location.search, 'page')) || 1;

  const [products, setProducts] = useState([]);
  const [numberPages, setNumberPages] = useState([]);

  const totalPages = products.length / productsPerPage;
  const indexOfLastProduct = page * productsPerPage;
  const indexOfFirsProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirsProduct, indexOfLastProduct);
  const currentProductsMobile = products.slice(0, indexOfLastProduct);

  const setPage = useCallback((newPage) => {
    history.push({
      search: `?page=${newPage}`
    });
  }, [history]);

  useEffect(() => {
    const nroPages = [];
    for (let productIndex = 1;
      productIndex <= Math.ceil(products.length / productsPerPage); productIndex += 1) {
      nroPages.push(productIndex);
    }
    setNumberPages(nroPages);
  }, [products]);

  useEffect(() => {
    const nroPages = [];
    for (let productIndex = 1;
      productIndex <= Math.ceil(products.length / productsPerPage); productIndex += 1) {
      nroPages.push(productIndex);
    }
    setNumberPages(nroPages);
  }, [products]);

  useEffect(() => {
    let newProducts = (getLocalProductsList() || []);
    if (filters && filters.sector) {
      newProducts = findBySector(newProducts, filters.sector);
    }
    if (filters && filters.line) {
      newProducts = findByProductLine(newProducts, filters.line);
    }
    if (filters && filters.category) {
      newProducts = findByCategory(newProducts, filters.category);
    }
    if (filters && filters.subCategory) {
      newProducts = findBySubCategory(newProducts, filters.subCategory);
    }
    if (filters && filters.term) {
      newProducts = newProducts.filter((p) => {
        const productName = p.name;
        const manufacturerName = p.manufacturer && p.manufacturer.name;
        if (productName && similarity(filters.term, productName) >= 0.66) {
          return true;
        }
        if (manufacturerName && similarity(filters.term, manufacturerName) >= 0.66) {
          return true;
        }
        const productString = JSON.stringify(p);
        const regex = new RegExp(filters.term, 'i');
        return productString ? productString.search(regex) > 0 : false;
      });
    }

    setProducts(sortArray(newProducts, 'name'));
  }, [filters]);

  const renderProducts = useCallback((pageProducts, currentFilterUrl) =>
    (pageProducts || []).map((p) => (
      <ProductCard
        key={p.id}
        onClick={() => {
          saveLocalLastFilterUrl(currentFilterUrl);
          history.push(`/products/details/${p.id}`);
        }}
      >
        <CardImage>
          {p.images && <ProductImg src={getProductImgUrl(p.images[0].path)} alt={p.name} />}
        </CardImage>
        <CardDescription>
          <DescOne>{p.name}</DescOne>
          {/* <DescTwo>
          <Span>CA</Span>
          {p.id}
        </DescTwo> */}
          {/* {p.shortDescription && (
          <DescThree>
            {p.shortDescription}
          </DescThree>
        )} */}
          {p.brand && (
            <DescFour>
              <Span>Marca</Span>
              {p.brand}
            </DescFour>
          )}
          {p.manufacturer && (
            <DescFour>
              {`Fabricante: ${p.manufacturer}`}
            </DescFour>
          )}
        </CardDescription>
      </ProductCard>
    )), [history]);

  return (
    <Root>
      <ProductsGrid>
        {renderProducts(currentProducts, `${location.pathname}${location.search}`, page)}
      </ProductsGrid>
      <ProductsGridMobile>
        {renderProducts(currentProductsMobile, `${location.pathname}${location.search}`, page)}
      </ProductsGridMobile>

      <Pagination>
        {page > 1 && (
          <PageButton onClick={() => setPage(page - 1)}>
            &#10094; Anterior
          </PageButton>
        )}

        {numberPages.map((numberPage) => numberPage < maxPaginationPages ? (
          <>
            <PageButton
              key={numberPage}
              isActive={numberPage === page}
              onClick={() => setPage(numberPage)}
            >
              {numberPage}
            </PageButton>
          </>
        ) : null)}

        {(numberPages.length || 1) >= maxPaginationPages && (
          <>
            {page === maxPaginationPages && (numberPages.length || 1) > maxPaginationPages && (
              <PageButton
                isActive
                onClick={() => setPage(page)}
              >
                {page}
              </PageButton>
            )}
            {page > maxPaginationPages && page < (numberPages.length || 1) - 1 && (
              <>
                ...
                <PageButton
                  isActive
                  onClick={() => setPage(page)}
                >
                  {page}
                </PageButton>
              </>
            )}
            {(numberPages.length || 1) > maxPaginationPages && (<>...</>)}
            {page > maxPaginationPages && page === (numberPages.length || 1) - 1 && (
              <>
                <PageButton
                  isActive
                  onClick={() => setPage(page)}
                >
                  {page}
                </PageButton>
              </>
            )}
            <PageButton
              isActive={(numberPages.length) === page}
              onClick={() => setPage(numberPages.length)}
            >
              {numberPages[numberPages.length - 1]}
            </PageButton>
          </>
        )}

        {page < totalPages && (
          <PageButton onClick={() => setPage(page + 1)}>
            Próxima &#10095;
          </PageButton>
        )}
      </Pagination>
      <PaginationMobile>
        {page < totalPages && (
          <PageButtonMobile onClick={() => setPage(page + 1)}>
            Ver mais
          </PageButtonMobile>
        )}
      </PaginationMobile>
    </Root>
  );
}

Products.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.shape({
    sector: PropTypes.string,
    line: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
    term: PropTypes.string,
  }),
};

Products.defaultProps = {
  history: undefined,
  location: undefined,
  filters: {},
};

export default Products;
