import axios from 'axios';

export async function getProducts(setLoading = () => { }) {
  let products;
  try {
    setLoading(true);
    const response = await axios.get('products/products.json');
    products = (response && response.data && response.data.products) || [];
  } catch (err) {
    products = undefined;
  } finally {
    setLoading(false);
  }
  return products;
}

export function getProductImgUrl(imagePath) {
  return `${getBasePath()}/${imagePath}`;
}

function getBasePath() {
  const servers = process.env.REACT_APP_SERVER_BASE_PATH.split(';');
  if (servers && servers.length > 1) {
    const foundedHost = servers.find((s) => s === window.location.origin);
    return foundedHost || window.location.origin;
  }
  return process.env.REACT_APP_SERVER_BASE_PATH;
}
