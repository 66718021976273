import logoName from 'assets/images/logo-name-small.png';
import logo from 'assets/images/logo-small.png';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0.5rem 0;
  user-select: none;
  width: var(--master-content-width);
  margin: 0 auto;

  @media (max-width: 1247px) {
    width: 100%;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;

export const LogoImg = styled.img.attrs(() => ({
  type: 'text',
  src: logo,
  alt: 'logo'
}))`
  height: 5rem;

  @media (max-width: 1247px) {
    margin-left: 0.5rem;
  }

  @media (max-width: 1134px) {
    height: 4rem;
  }
`;

export const SubLogo = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg);

  & > a {
    text-decoration: none;
  }

  @media (max-width: 340px) {
    margin: 0 0 0 0.5rem;
  }
`;

export const SubLogoImg = styled.img.attrs(() => ({
  type: 'text',
  src: logoName,
  alt: 'logo-name'
}))`
  width: 7rem;
  border-radius: 0.2rem;
  box-shadow: var(--text-color) 0px 0px 3px 2px;
  padding: 0.2rem;
  background-color: var(--secondary-color);

  @media (max-width: 1134px) {
    width: 5rem;
  }
`;

export const SubLogoText = styled.div`
  width: 12rem;
  margin: 0.3rem 0 0 0;
  font-size: 1rem;
  color: #fff;
  text-shadow: 1px 2px 4px var(--text-color);

  @media (max-width: 1134px) {
    width: 10rem;
    font-size: 0.8rem;
  }

  @media (max-width: 340px) {
    width: 8.6rem;
    font-size: 0.8rem;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 1rem 0 0;

  & > :first-child {
    border-left: 2px solid var(--primary-color-light);
  }

  @media (max-width: 1071px) {
    display: none;
  }
`;

export const NavButton = styled.div`
  height: 100%;
  border-right: 2px solid var(--primary-color-light);

  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg);

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  :hover {
    background-color: var(--primary-color-light);
  };

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    padding: 0 1rem;
    text-decoration: none;
    color: #fff;
    text-shadow: 1px 2px 4px var(--text-color);

    @media (max-width: 1199px) {
      font-size: 0.85rem;
      padding: 0 1rem;
    }
  }
`;

export const Media = styled.div`
  align-items: center;
  display: flex;
  margin-left: 1rem;

  & > div {
    flex-wrap: nowrap;
  }

  a {
    font-size: 1.5rem;

    @media (max-width: 1090px) {
      font-size: 1.3rem;
    }
  }
`;

export const MobileMenu = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  margin-right: 1rem;

  svg {
    cursor: pointer;
  }

  @media (max-width: 1071px) {
    display: flex;
  }

  @media (max-width: 340px) {
    justify-content: center;
    margin-right: 0;
  }
`;

export const MobileNavBase = styled.div` 
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0 0 0 / 30%);
  z-index: 1000;
  visibility: ${(props) => props.active ? 'visible' : 'hidden'};
`;

export const MobileBase = styled.div`
  width: 60%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  position: fixed;
  transform: ${(props) => props.active ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;
  padding: 2rem 0.5rem;
  z-index: 1000;

  @media (max-width: 390px) {
    width: 90%;
  }
`;

export const MobileNavSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileNav = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-size: 1rem;
  
  padding: 0.2rem;
  margin: 0.2rem 0;
  border-bottom: 1px solid var(--terciary-color);

  svg {
    margin-right: 0.5rem;
  }
  
  a {
    display: flex;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 0.2rem;
    padding: 0.8rem 0.2rem;

    :hover {
      background-color: rgba(0 0 0 / 20%);
    }
  }
`;
