import React, { useState } from 'react';
import { BsInfoSquareFill } from 'react-icons/bs';
import { FaListAlt } from 'react-icons/fa';
import { MdHome, MdContactMail, MdShoppingCart } from 'react-icons/md';
import { RiMenuLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import {
  Root, Logo, LogoImg, SubLogo, SubLogoImg,
  SubLogoText, NavBar, NavButton, Media,
  MobileMenu, MobileNavBase, MobileNav,
  MobileNavSide, MobileBase
} from 'components/Header/styles';
import SocialMedia from 'components/SocialMedia';

export const navMap = [
  { name: 'Principal', to: '/', icon: <MdHome /> },
  { name: 'Produtos', to: '/products', icon: <MdShoppingCart /> },
  { name: 'Catálogo', to: '/catalog', icon: <FaListAlt /> },
  { name: 'Sobre nós', to: '/about-us', icon: <BsInfoSquareFill /> },
  { name: 'Contato / Orçamentos', to: '/contact', icon: <MdContactMail /> },
];

function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <Root>
      <Logo>
        <Link to="/">
          <LogoImg />
        </Link>
      </Logo>
      <SubLogo>
        <Link to="/">
          <SubLogoImg />
          <SubLogoText>Equipamentos de Segurança e Uniformes</SubLogoText>
        </Link>
      </SubLogo>

      <NavBar>
        {navMap.map((nav) => (
          <NavButton key={nav.name}>
            <Link to={nav.to}>
              {nav.icon}
              {nav.name}
            </Link>
          </NavButton>
        ))}
        <Media>
          <SocialMedia />
        </Media>
      </NavBar>

      <MobileMenu>
        <RiMenuLine onClick={() => setMobileMenu((prev) => !prev)} />
        <MobileNavBase active={mobileMenu} onClick={() => setMobileMenu(false)} />
        <MobileBase active={mobileMenu}>
          <MobileNavSide onClick={(e) => e.stopPropagation()}>
            {navMap.map((nav) => (
              <MobileNav key={nav.name}>
                <Link to={nav.to} onClick={() => setMobileMenu(false)}>
                  {nav.icon}
                  {nav.name}
                </Link>
              </MobileNav>
            ))}
          </MobileNavSide>
        </MobileBase>
      </MobileMenu>
    </Root>
  );
}

Header.propTypes = {
};

Header.defaultProps = {
};

export default Header;
