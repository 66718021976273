import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
`;

export const MediaButton = styled.a`
  font-size: 2rem;
  margin-right: 0.5rem;
  color: var(--terciary-color-light);

  :last-child {
    margin-right: 0;
  }

  :hover{
    cursor: pointer;
  }

  .facebook:hover {
    //color: #3b5998;
    color: var(--terciary-color);
  }

  .instagram:hover {
    //color: #cc2366;
    color: var(--terciary-color);
  }

  .whatsapp:hover {
    //color: #25D366;
    color: var(--terciary-color);
  }

  @media (max-width: 450px) {
    font-size: 1.7rem;
  }

`;
