import React from 'react';

import { BackgroundPage, Container, Content, AboutUsTitle, Text, Title, OurDifferentials, Ul, Li } from './styles';

function AboutUs() {
  return (
    <>
      <BackgroundPage />
      <Container>
        <Content>
          <AboutUsTitle>Sobre nós</AboutUsTitle>
          <Text>
            Fundada em 17 de junho de 2004, a VDM é uma empresa brasileira,
            sediada em Uberlândia/MG, especializada em
            equipamentos de segurança do trabalho para atender os mais diversos seguimentos
            (indústriais, áreas hospitalares, coleta
            seletiva de lixo, limpeza, setor sucroalcooleiro, órgãos públicos e etc.).
          </Text>
          <Text>
            Atendemos clientes em todo o territótio nacional, com produtos qualificados dentro
            da política de atuação, transparência
            nas relações e prática de preços competitivos.
          </Text>
          <Text>
            Produtos que garantem a segurança do trabalhador, qualidade de vida, maior desempenho
            e produtividade. Investimentos
            continuos em pesquisas, desenvolvimento de produtos, novas tecnologias e processos
            produtivos.
          </Text>
          <Text>
            A VDM é uma empresa que preza pela qualidade do serviço e satisfação do cliente em
            primeiro lugar, sempre inovando com
            conhecimento em equipamentos de segurança.
          </Text>
          <OurDifferentials>
            <Title>Nossos diferenciais</Title>
            <Ul>
              <Li>
                Oferecemos consultoria para cada tipo de atividade;
              </Li>
              <Li>
                Equipe altamente capacitada e trinada;
              </Li>
              <Li>
                Agilidade na entrega;
              </Li>
              <Li>
                Fabricação própria;
              </Li>
              <Li>
                Buscamos sempre atualizar nossos clientes sobre novos produtos no mercado;
              </Li>
              <Li>
                Trabalho intensivo de pós vendas e relacionamento continúo;
              </Li>
              <Li>
                Suporte técnico permanente, com visitas técnicas, palestras e outros serviços
                agregados;
              </Li>
            </Ul>
          </OurDifferentials>
        </Content>
      </Container>
    </>
  );
}
AboutUs.propTypes = {
};

AboutUs.defaultProps = {
};

export default AboutUs;
