import { getLocalProductsList } from './localStorage';
import { getProductLines, getProductCategories, getProductSubCategories, getProductSectors } from './products';

const isSector = (filter) => getProductSectors().find((s) => s.value === filter);
const isProductLine = (filter) => getProductLines().find((pl) => pl.value === filter);
const isCategories = (filter) => getProductCategories().find((c) => c.value === filter);
const isSubCategories = (filter) => getProductSubCategories().find((sc) => sc.value === filter);

export const routeFiltersParse = (routeFilter) => {
  const parsedFilter = {};
  const innerFilters = routeFilter.split('-');
  innerFilters.forEach((filter) => {
    if (isSector(filter)) {
      parsedFilter.sector = filter;
    } else if (isProductLine(filter)) {
      parsedFilter.line = filter;
    } else if (isCategories(filter)) {
      parsedFilter.category = filter;
    } else if (isSubCategories(filter)) {
      parsedFilter.subCategory = filter;
    } else {
      parsedFilter.term = filter;
    }
  });
  return parsedFilter;
};

export const createBreadCrumbsFilters = (filters) => {
  const newCrumbs = [{ label: 'Limpar filtros', to: '/products' }];
  if (filters && filters.sector) {
    const sector = getProductSectors().find((s) => s.value === filters.sector);
    if (sector && sector.label && sector.value) {
      newCrumbs.push({ label: sector.label, to: `/products/${sector.value}` });
    }
  }
  if (filters && filters.line) {
    const line = getProductLines().find((pl) => pl.value === filters.line);
    const prevFilters = `${filters.sector ? `${filters.sector}-` : ''}`;
    if (line && line.label && line.value) {
      newCrumbs.push({
        label: line.label,
        to: `/products/${prevFilters ? `${prevFilters}-${line.value}` : line.value}`
      });
    }
  }
  if (filters && filters.category) {
    const category = getProductCategories().find((c) => c.value === filters.category);
    let prevFilters = `${filters.sector ? `${filters.sector}-` : ''}`;
    prevFilters = `${filters.line ? `${filters.line}-` : ''}`;
    if (category && category.label && category.value) {
      newCrumbs.push({
        label: category.label,
        to: `/products/${prevFilters ? `${prevFilters}-${category.value}` : category.value}`
      });
    }
  }
  if (filters && filters.subCategory) {
    const subCategory = getProductSubCategories().find((sc) => sc.value === filters.subCategory);
    let prevFilters = `${filters.sector ? `${filters.sector}-` : ''}`;
    prevFilters = `${filters.line ? `${filters.line}-` : ''}`;
    prevFilters = `${filters.category ? `${filters.category}-` : ''}`;
    if (subCategory && subCategory.label && subCategory.value) {
      newCrumbs.push({
        label: subCategory.label,
        to: `/products/${prevFilters ? `${prevFilters}-${subCategory.value}` : subCategory.value}`
      });
    }
  }
  if (filters && filters.term) {
    const lowerTerm = filters.term.toLowerCase();
    if (lowerTerm) {
      newCrumbs.push({ label: lowerTerm, to: `/products/${lowerTerm}` });
    }
  }
  return newCrumbs;
};

export const createBreadCrumbsProduct = (productId, lastFilterUrl) => {
  const newCrumbs = [{ label: 'Voltar à lista', to: lastFilterUrl || '/products' }];
  const product = (getLocalProductsList() || []).find((p) => p.id === productId);
  if (product && product.name) {
    newCrumbs.push({ label: product.name, to: `/products/${productId}` });
  }
  return newCrumbs;
};
