import React, { useEffect } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { BsCone } from 'react-icons/bs';
import {
  FaTractor, FaHardHat, FaHandPaper, FaCloudSun, FaWineBottle
} from 'react-icons/fa';
import {
  GiWalkingBoot, GiBelt, GiHotSurface, GiSugarCane,
  GiWaterDrop, GiLegArmor, GiTShirt, GiBrandyBottle,
  GiForearm, GiLeatherVest, GiPapers, GiProtectionGlasses,
  GiSoap, GiChainsaw
} from 'react-icons/gi';
import { HiLightBulb } from 'react-icons/hi';
import { IoMdConstruct } from 'react-icons/io';
import {
  RiBuilding2Fill, RiToolsFill, RiCarWashingFill,
  RiSurgicalMaskFill
} from 'react-icons/ri';

import epiAgriculture from 'assets/images/epi-agriculture.jpg';
import epiCarWash from 'assets/images/epi-carwash.jpg';
import epiCarWorkShop from 'assets/images/epi-carworkshop.jpg';
import epiChainsaw from 'assets/images/epi-chainsaw.png';
import epiConstructorCivil from 'assets/images/epi-constructor-civil.jpg';
import epiHeigth from 'assets/images/epi-height.jpg';
import epiSugarCane from 'assets/images/epi-sugarcane.jpg';
import epiWelder from 'assets/images/epi-welder.jpg';
import epiWorker from 'assets/images/epi-worker.jpg';
import PropTypes from 'prop-types';
import { getProducts } from 'services/products';

import { sortArray } from './array';
import { saveLocalProductsList, getLocalProductsList } from './localStorage';
import { cleanUpSpecialChars, titleCase } from './string';

function Products({ setProducts }) {
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await getProducts();
      if (response && response.length && response.length > 0) {
        response.map((product) => {
          const newProduct = { ...product, name: titleCase(product.name) };
          // if (newProduct.variations && newProduct.variations.length
          //   && newProduct.variations.length > 0) {
          //   newProduct.variations = newProduct.variations.map((v) => {
          //     const newVariation = { ...v };
          //     if (newVariation.value && newVariation.value.length
          //       && newVariation.value.length > 0) {
          //       newVariation.value = titleCase(newVariation.value);
          //     }
          //     return newVariation;
          //   });
          // }
          return newProduct;
        });
        saveLocalProductsList(response);
        setProducts(response);
      }
    };

    fetchProducts();
  }, [setProducts]);

  return <></>;
}

Products.propTypes = {
  setProducts: PropTypes.func
};

Products.defaultProps = {
  setProducts: () => { }
};

export default Products;

const productLinesMap = {
  fabricacaopropria: {
    label: 'Fabricação própria',
    value: 'fabricacaopropria',
    icon: <HiLightBulb />
  },
  cabecaeface: {
    label: 'Cabeça e face',
    value: 'cabecaeface',
    icon: <FaHardHat />
  },
  calcados: {
    label: 'Calçados',
    value: 'calcados',
    icon: <GiWalkingBoot />
  },
  cinturoes: {
    label: 'Cinturões',
    value: 'cinturoes',
    icon: <GiBelt />
  },
  garrafas: {
    label: 'Garrafas',
    value: 'garrafas',
    icon: <FaWineBottle />
  },
  limas: {
    label: 'Limas',
    value: 'limas',
    icon: <RiToolsFill />
  },
  luvas: {
    label: 'Luvas',
    value: 'luvas',
    icon: <FaHandPaper />
  },
  protecaoparaacabeca: {
    label: 'Proteção para a cabeça',
    value: 'protecaoparaacabeca',
    icon: <FaHardHat />
  },
  'termoprato/marmitas': {
    label: 'Termoprato/Marmitas',
    value: 'termoprato/marmitas',
    icon: <GiHotSurface />
  },
  trabalhoemaltura: {
    label: 'Trabalho em altura',
    value: 'trabalhoemaltura',
    icon: <FaCloudSun />
  },
  impermeaveis: {
    label: 'Impermeáveis',
    value: 'impermeaveis',
    icon: <GiWaterDrop />
  },
  vestimentas: {
    label: 'Vestimentas',
    value: 'vestimentas',
    icon: <GiTShirt />
  },
  eva: {
    label: 'Eva',
    value: 'eva',
    icon: <GiPapers />
  },
  perneiras: {
    label: 'Perneiras',
    value: 'perneiras',
    icon: <GiLegArmor />
  },
  aventais: {
    label: 'Aventais',
    value: 'aventais',
    icon: <GiLeatherVest />
  },
  mangote: {
    label: 'Mangote',
    value: 'mangote',
    icon: <GiForearm />
  },
  cones: {
    label: 'Cones',
    value: 'cones',
    icon: <BsCone />
  },
  protecaosolar: {
    label: 'Proteção solar',
    value: 'protecaosolar',
    icon: <GiBrandyBottle />
  },
  respiratorio: {
    label: 'Respiratório',
    value: 'respiratorio',
    icon: <RiSurgicalMaskFill />
  },
  higienizacaoeprotecao: {
    label: 'Higienização e proteção',
    value: 'higienizacaoeprotecao',
    icon: <GiSoap />
  }
};

const sectorMap = {
  agronegocio: {
    label: 'Agronegócio',
    value: 'agronegocio',
    icon: <FaTractor />,
    src: epiAgriculture,
    alt: 'epi agriculture',
    subtitle: 'Conheça nossos equipamentos para a proteção no campo'
  },
  industrial: {
    label: 'Industrial',
    value: 'industrial',
    icon: <RiBuilding2Fill />,
    src: epiWorker,
    alt: 'Industrial',
    subtitle: 'Venha conferir nossa linha de produtos indústriais'
  },
  construcaocivil: {
    label: 'Construção civil',
    value: 'construcaocivil',
    icon: <IoMdConstruct />,
    src: epiConstructorCivil,
    alt: 'Construção civil',
    subtitle: 'Venha conferir nossos produtos para sua obra'
  },
  canavieiro: {
    label: 'Canavieiro',
    value: 'canavieiro',
    icon: <GiSugarCane />,
    src: epiSugarCane,
    alt: 'Canavieiro',
    subtitle: 'Veja aqui nossas opções para canavieiros'
  },
  limpezas: {
    label: 'Limpezas',
    value: 'limpezas',
    icon: <AiOutlineClear />,
    src: epiHeigth,
    alt: 'Limpezas',
    subtitle: 'Conheça nossos equipamentos de segurança para limpezas'
  },
  oficina: {
    label: 'Oficina',
    value: 'oficina',
    icon: <IoMdConstruct />,
    src: epiCarWorkShop,
    alt: 'Oficina',
    subtitle: 'Venha conferir nossos equipamentos para sua oficina'
  },
  lavajato: {
    label: 'Lava jato',
    value: 'lavajato',
    icon: <RiCarWashingFill />,
    src: epiCarWash,
    alt: 'Lava jato',
    subtitle: 'Venha conferir nossos produtos para seu lava jato'
  },
  soldador: {
    label: 'Soldador',
    value: 'soldador',
    icon: <GiProtectionGlasses />,
    src: epiWelder,
    alt: 'Soldador',
    subtitle: 'Veja aqui tudo para você soldador'
  },
  motosserrista: {
    label: 'Motosserrista',
    value: 'motosserrista',
    icon: <GiChainsaw />,
    src: epiChainsaw,
    alt: 'Motosserrista',
    subtitle: 'Conheça nossos produtos para trabalho com motosserra'
  }
};

const foundInMap = (map, string, type) => {
  if (map && string && type) {
    let founded;
    Object.entries(map || {}).forEach(([key, value], index) => {
      if (key === cleanUpSpecialChars(string.toLowerCase())) {
        founded = { ...value, order: (index + 1) };
      }
    });
    if (!founded) {
      console.info(`${type.toUpperCase()} (${string} -> ${cleanUpSpecialChars(string.toLowerCase())})`);
    }
    return founded;
  }
  return undefined;
};

function uniqueArray(array) {
  const lowerArray = array.map((v) => v && cleanUpSpecialChars(v.toLowerCase()));
  return lowerArray.filter((v, i) =>
    v && lowerArray.indexOf(v) === i);
}

function filterProductsByTypes(filters, type) {
  let list = (getLocalProductsList() || []);
  if (filters && filters.sector && type !== 'sector') {
    list = findBySector(list, filters.sector);
  }
  if (filters && filters.line && type !== 'line') {
    list = findByProductLine(list, filters.line);
  }
  if (filters && filters.category && type !== 'category') {
    list = findByCategory(list, filters.category);
  }
  if (filters && filters.subCategory && type !== 'subCategory') {
    list = findBySubCategory(list, filters.subCategory);
  }
  return list;
}

function getTypesEnriched(filters, type, object, enrichedMap = undefined) {
  const filteredProducts = filterProductsByTypes(filters, type);
  const types = filteredProducts.map((p) => p[object]);
  const uniqueTypes = uniqueArray(types.flat());
  if (enrichedMap) {
    const typesEnriched = uniqueTypes.map((t) =>
      foundInMap(enrichedMap, t, type));
    const typesEnrichedNonNull = typesEnriched.filter((pl) => pl) || [];
    const typesEnrichedSorted = typesEnrichedNonNull.sort((a, b) =>
      a.order && b.order ? a.order - b.order : 0);
    return typesEnrichedSorted;
  }
  return uniqueTypes;
}

export function getProductSectors(filters) {
  const type = 'sector';
  const object = 'industries';
  const sectors = getTypesEnriched(filters, type, object, sectorMap);
  return sortArray(sectors, 'label');
}

export function getProductLines(filters) {
  const type = 'line';
  const object = 'line';
  const lines = getTypesEnriched(filters, type, object, productLinesMap);
  return sortArray(lines, 'label');
}

export function getProductCategories(filters) {
  const type = 'category';
  const object = 'categories';
  const categories = getTypesEnriched(filters, type, object);
  return sortArray(categories, 'label');
}

export function getProductSubCategories(filters) {
  const type = 'subCategory';
  const object = 'subCategories';
  const subCategories = getTypesEnriched(filters, type, object);
  return sortArray(subCategories, 'label');
}

export const findBySector = (products, sector) =>
  products.filter((p) => {
    const cleanedSectors = (p.industries || []).map(
      (i) => i && cleanUpSpecialChars(i.toLowerCase())
    );
    return cleanedSectors.includes(sector);
  });

export const findByProductLine = (products, productLine) =>
  products.filter((p) => {
    const cleanedLines = (p.line || []).map(
      (c) => c && cleanUpSpecialChars(c.toLowerCase())
    );
    return cleanedLines.includes(productLine);
  });

export const findByCategory = (products, category) =>
  products.filter((p) => {
    const cleanedCategories = (p.categories || []).map(
      (c) => c && cleanUpSpecialChars(c.toLowerCase())
    );
    return cleanedCategories.includes(category);
  });

export const findBySubCategory = (products, subCategory) =>
  products.filter((p) => {
    const cleanedSubCategories = (p.subCategories || []).map(
      (sc) => sc && cleanUpSpecialChars(sc.toLowerCase())
    );
    return cleanedSubCategories.includes(subCategory);
  });
