import React from 'react';
import { RiFacebookBoxFill, RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';

import { Root, MediaButton } from 'components/SocialMedia/styles';

function SocialMedia() {
  return (
    <Root>
      <MediaButton href="https://www.facebook.com/VDM-Equipamentos-de-Seguran%C3%A7a-e-Uniformes-Ltda-EPP-1664078443851658/" target="blank">
        <RiFacebookBoxFill className="facebook" />
      </MediaButton>
      <MediaButton href="https://www.instagram.com/vdmequipamentosde/" target="blank">
        <RiInstagramFill className="instagram" />
      </MediaButton>
      <MediaButton href="https://wa.me/+553492160075/" target="blank">
        <RiWhatsappFill className="whatsapp" />
      </MediaButton>
    </Root>
  );
}

SocialMedia.propTypes = {
};

SocialMedia.defaultProps = {
};

export default SocialMedia;
