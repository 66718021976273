import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 0.5rem;
  width: var(--master-content-width);
  margin: 0 auto;

  @media (max-width: 1247px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  display: flex;
  text-align: center;
  margin-top: 1rem;

  svg {
    margin-right: 0.2rem;
  }

  @media (max-width: 915px) {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

export const Tabs = styled.div`
   display: flex;
   flex-direction: row;

   @media (max-width: 915px) {
    flex-direction: column;

    :first-child {
      height: fit-content;
    }
   }
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  //height: 10rem;
  flex-direction: column;
  width: 100%;

  img {
    margin: 0;
  }

  @media (max-width: 915px) {
    //margin-top: 1rem;

    :first-child {
      margin-top: 0;
    }
  }
`;

export const BottonInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  margin-top: 1.8rem;

  @media (max-width: 915px) {
    margin-top: 1rem;
    text-align: center;
  }
`;

export const FooterLink = styled(Link)`
  display: flex;
  color: white;
  text-decoration: none;
  justify-content: center;
  font-weight: bold;
  margin-top: 0.5rem;

  :hover {
    color: var(--terciary-color);
  }

  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubText = styled.p`
  text-align: center;
  font-weight: bold;
  margin-top: 0.2rem;

  a {
    text-decoration: none;
    color: #ffffff;

    :hover {
      color: var(--terciary-color);
    }
  }

  @media (max-width: 915px) {
    margin-top: 0;
  }

  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;
