import React from 'react';

import PropTypes from 'prop-types';

import { Root, InputStyle, Label } from './styles';

function InputSelect({ id, label, extraStyles, ...rest }) {
  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 15,
      width: 15,
    },
  });

  const customStyles = {
    control: (styles) => ({
      ...styles,
      fontSize: '1rem',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 'unset',
      borderBottom: '1px solid #7e8083',
      borderColor: '#7e8083',
      outline: 'none',
      boxShadow: 'unset',
      ':hover': {
        borderColor: '#35383b',
      }
    }),
    input: (styles, { data }) => ({ ...styles, ...data && data.color ? dot() : [] }),
    placeholder: (styles, { data }) => ({ ...styles, ...data && data.color ? dot() : [] }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...data && data.color ? dot(data.color) : []
    }),
    option: (styles, { data, isSelected }) => ({
      ...styles,
      ...data && data.color ? dot(data.color) : [],
      ':hover': {
        opacity: 0.7,
      },
      backgroundColor: isSelected ? 'rgba(71, 141, 209, 1)' : 'transparent',
    }),
    ...extraStyles
  };

  return (
    <Root>
      {label && (<Label htmlFor={id}>{label}</Label>)}
      <InputStyle id={id} styles={customStyles} {...rest} />
    </Root>
  );
}

InputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  extraStyles: PropTypes.shape({})
};

InputSelect.defaultProps = {
  label: undefined,
  extraStyles: {}
};

export default InputSelect;
