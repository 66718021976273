import styled from 'styled-components';

export const BreadCrumbsContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.9rem;
  padding: 1.5rem 0 0 0;
  
  span {
    padding-bottom: 0.2rem;
  }

  a {
    text-decoration: none;
    color: var(--primary-color);
    padding-bottom: 0.2rem;

    :hover {
      opacity: 0.5;
    }
  }
`;

export const BreadCrumbFilter = styled.div`
  display: flex;
  
  :first-child {
    span {
      color: var(--text-color-light);
    }
  }
`;

export const Divisor = styled.span`
  font-size: 0.8rem;
  margin: 0 0.8rem;
  color: var(--text-color);
`;
