import React, { useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';

import PropTypes from 'prop-types';

import { Root, InputText, ButtonBase, Button, VerticalDivider } from './styles';

function Search({ history }) {
  const [search, setSearch] = useState('');

  return (
    <Root>
      <InputText
        onKeyPress={(e) => { if (history && history.push && e.key === 'Enter') { history.push(`/products/${search}`); } }}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder="Procure por código, nome, marca..."
      />
      <ButtonBase>
        <VerticalDivider />
        <Button onClick={() => { if (history && history.push) { history.push(`/products/${search}`); } }}>
          <RiSearchLine />
        </Button>
      </ButtonBase>
    </Root>
  );
}

Search.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Search.defaultProps = {
  history: undefined,
  location: {}
};

export default Search;
