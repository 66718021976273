import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal } from 'components/Modal';
import PropTypes from 'prop-types';
import { useInterval } from 'utils/useInterval';

import {
  Root, SlideContainer, Slide, NavButton, DotsBase, Dot,
  ThumbnailsBase, Thumbnail, ImageModal,
  ImageModalContainer
} from './styles';

function Carousel({
  children, dots, arrows, thumbnails, activeThumbnail,
  slideShowDelay, hasZoom
}) {
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [imageModal, setImageModal] = useState(undefined);
  const [zoom, setZoom] = useState(0);

  const outsideImageModalRef = useRef();

  useEffect(() => {
    items.forEach((item, index) => {
      if (item && item.props && item.props.src && item.props.src === activeThumbnail) {
        setActive(index);
      }
    });
  }, [items, activeThumbnail]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (outsideImageModalRef.current && !outsideImageModalRef.current.contains(e.target)) {
        setOpenModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(children)) {
      setItems(children);
    } else {
      setItems([children]);
    }
  }, [children]);

  useInterval(() => {
    nextSlide();
  }, slideShowDelay);

  const nextSlide = useCallback(() => (
    setActive((prevActive) =>
      (prevActive + 1) < items.length ? (prevActive + 1) : 0)
  ), [items.length]);

  const lastSlide = useCallback(() => (
    setActive((prevActive) =>
      (prevActive - 1) > 0 ? (prevActive - 1) : (items.length - 1))
  ), [items.length]);

  function getImgChild(node) {
    if (node && node.type && node.type.target && node.type.target === 'img') {
      return node;
    }
    if (node && node.props && node.props.children) {
      return getImgChild(node.props.children);
    }
    return undefined;
  }

  return (
    <Root>
      <SlideContainer>
        {items.map((item, index) => (
          <Slide onClick={() => { setOpenModal(true); setImageModal(item); }} key={item.key} id={`carousel-${index}`} isActive={active === index}>
            {item}
          </Slide>
        ))}
        {dots && (
          <DotsBase>
            {items.map((item, index) => (
              <Dot onClick={() => setActive(index)} key={item.key} id={`dot-${index}`} isActive={active === index} />))}
          </DotsBase>
        )}
        {arrows && (
          <>
            <NavButton onClick={() => lastSlide()}>
              &#10094;
            </NavButton>
            <NavButton onClick={() => nextSlide()}>
              &#10095;
            </NavButton>
          </>
        )}
      </SlideContainer>

      {thumbnails && (
        <ThumbnailsBase>
          {items.map((item, index) => (
            <Thumbnail onClick={() => setActive(index)} key={item.key} id={`dot-${index}`} isActive={active === index}>
              {getImgChild(item)}
            </Thumbnail>
          ))}
        </ThumbnailsBase>
      )}
      {hasZoom && (
        <Modal open={openModal}>
          <ImageModalContainer>
            <ImageModal
              ref={outsideImageModalRef}
              zoom={zoom}
              onClick={() => zoom === 0 ? setZoom(1) : setZoom(0)}
            >
              {imageModal}
            </ImageModal>
          </ImageModalContainer>
        </Modal>
      )}
    </Root>
  );
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  slideShowDelay: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  thumbnails: PropTypes.bool,
  hasZoom: PropTypes.bool,
  activeThumbnail: PropTypes.number
};

Carousel.defaultProps = {
  children: undefined,
  slideShowDelay: 0,
  dots: false,
  arrows: false,
  thumbnails: false,
  hasZoom: undefined,
  activeThumbnail: undefined
};

export default Carousel;
