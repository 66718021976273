import React, { useCallback, useEffect, useState } from 'react';
import { MdShoppingCart } from 'react-icons/md';

import Carousel from 'components/Carousel';
import Form from 'components/Form';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import PropTypes from 'prop-types';
import { getProductImgUrl } from 'services/products';
import { getImageVariant } from 'utils/image';
import { getLocalProductsList, saveLocalProduct } from 'utils/localStorage';

import {
  Root, Info, CarouselBase, CarouselImg, Detail, Variations,
  DescriptionContainer, DetOne, DetFour, Cart, CartButton, DetThree,
  LongDescription, DescriptionName, Descriptions, ProductDescriptions,
  TitleDescription, Especifications, Description, DetailContainer,
} from './styles';

function Details({ match, history }) {
  const productId = match.params.id;

  const [product, setProduct] = useState({});
  const [activeThumbnail, setActiveThumbnail] = useState(0);

  useEffect(() => {
    if (productId) {
      const foundendProduct = (getLocalProductsList() || []).find((p) => p.id === productId);
      setProduct(foundendProduct);
    }
  }, [productId]);

  const addToCart = useCallback((selectedProduct, formData) => {
    const selectedVariations = {};
    Object.entries(formData).forEach(([key, val]) => {
      selectedVariations[key] = val.value;
    });
    saveLocalProduct({ ...selectedProduct, selectedVariations });
    history.push('/contact');
  }, [history]);

  function handleChangeImage(option) {
    const image = getImageVariant(product.images, option.value);
    if (image) {
      setActiveThumbnail(getProductImgUrl(image.path));
    }
  }

  return (
    <Root>
      {product && product.name && (
        <>
          <Info>
            <CarouselBase>
              <Carousel arrows thumbnails hasZoom activeThumbnail={activeThumbnail}>
                {product && product.images ? product.images.map((image, index) => (
                  <CarouselImg
                    key={`${product.name}-${image.path}`}
                    src={getProductImgUrl(image.path)}
                    alt={`${product.name}-${index}`}
                  />
                )) : <></>}
              </Carousel>
            </CarouselBase>
            <DetailContainer>
              <Form onSubmit={(e, data) => addToCart(product, data)}>
                <Detail>
                  <DetOne>{product.name}</DetOne>
                  {/* <DetTwo>
                    <Span>CA</Span>
                    {product.id}
                  </DetTwo> */}
                  {product.shortDescription && (
                    <DetThree>
                      {product.shortDescription}
                    </DetThree>
                  )}
                  {product.manufacturer && (
                    <DetFour>
                      {`Fabricante: ${product.manufacturer.name}`}
                    </DetFour>
                  )}
                  {product.brand && (
                    <DetFour>
                      {`Marca: ${product.brand}`}
                    </DetFour>
                  )}
                  <Variations>
                    {(product.variations || []).map((variation) => {
                      if (variation && variation.ptBrName && variation.value) {
                        const options = (variation.value || []).map((val) =>
                          ({ value: val.ptBrName, label: val.ptBrName, color: val.hex }));
                        return (
                          <Input
                            key={`variation-${variation.ptBrName}`}
                            fieldId={variation.ptBrName}
                            required
                            requiredMessage="Selecione uma opção"
                          >
                            <InputSelect
                              id={variation.ptBrName}
                              label={variation.ptBrName}
                              options={options}
                              placeholder="Selecione uma opção"
                              onChange={(option) => handleChangeImage(option)}
                            />
                          </Input>
                        );
                      }
                      return undefined;
                    })}
                  </Variations>
                </Detail>
                <Cart>
                  <CartButton type="submit">
                    + Orçamento
                    <MdShoppingCart />
                  </CartButton>
                </Cart>
              </Form>
            </DetailContainer>
          </Info>
          <DescriptionContainer>
            <TitleDescription> Descrição / Especificação</TitleDescription>
            {product.longDescription && (
              <LongDescription>{product.longDescription}</LongDescription>
            )}
            {product.technicalSpecifications && (
              <Especifications>
                {Object.entries(product.technicalSpecifications).map(([tsKey, ts]) => (
                  <ProductDescriptions key={tsKey}>
                    <DescriptionName>
                      {`${ts.ptBrName}`}
                    </DescriptionName>
                    {Array.isArray(ts.value) ? (
                      <Descriptions>
                        {ts.value.map((esp) => (
                          <Description key={`${tsKey}-${esp.key}`}>
                            <DescriptionName>
                              {`${esp.name}`}
                            </DescriptionName>
                            <DescriptionName>
                              {`${esp.value}${esp.measurementUnit || ''}`}
                            </DescriptionName>
                          </Description>
                        ))}
                      </Descriptions>
                    ) : (
                      <DescriptionName>
                        {ts.value}
                      </DescriptionName>
                    )}
                  </ProductDescriptions>
                ))}
              </Especifications>
            )}
            {product.characteristics && (
              <Especifications>
                {Object.entries(product.characteristics).map(([cKey, cValue]) => (
                  <ProductDescriptions key={cKey}>
                    <DescriptionName>
                      {cKey}
                    </DescriptionName>
                    <DescriptionName>
                      {cValue}
                    </DescriptionName>
                  </ProductDescriptions>
                ))}
              </Especifications>
            )}
          </DescriptionContainer>
        </>
      )}
    </Root>
  );
}

Details.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Details.defaultProps = {
  history: undefined,
  match: undefined
};

export default Details;
