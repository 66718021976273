import React, { useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

import { MobileNavSide, MobileNavBase, MobileBase } from 'components/Header/styles';
import PropTypes from 'prop-types';
import { getProductCategories, getProductLines, getProductSectors, getProductSubCategories } from 'utils/products';

import {
  Root, Category, CategoryList, CategoryItem,
  Filters, MobileFilters, ModalTitle, ModalContent
} from './styles';

function SideBar({ filters, routeFilters, hideFilters }) {
  const [mobileFilter, setMobileFilter] = useState(false);

  const sectorFilters = () => {
    const sectors = getProductSectors(filters);
    return sectors && sectors.length && sectors.length > 0 ? (
      <Category>
        Setor
        <CategoryList>
          {sectors.map((pl) => (
            <CategoryItem key={pl.label}>
              <NavLink to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${pl.value}` : pl.value)}`}>
                {pl.icon}
                {pl.label}
              </NavLink>
            </CategoryItem>
          ))}
        </CategoryList>
      </Category>
    ) : <></>;
  };

  const lineFilters = () => {
    const lines = getProductLines(filters);
    return lines && lines.length && lines.length > 0 ? (
      <Category>
        Linhas de produtos
        <CategoryList>
          {lines.map((pl) => (
            <CategoryItem key={pl.label}>
              <NavLink to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${pl.value}` : pl.value)}`}>
                {pl.icon}
                {pl.label}
              </NavLink>
            </CategoryItem>
          ))}
        </CategoryList>
      </Category>
    ) : <></>;
  };

  const categoriesFilters = () => {
    const categories = getProductCategories(filters);
    return categories && categories.length && categories.length > 0 ? (
      <Category>
        Categoria
        <CategoryList>
          {categories.map((c) => (
            <CategoryItem key={c.label}>
              <NavLink to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${c.value}` : c.value)}`}>
                {c.label}
              </NavLink>
            </CategoryItem>
          ))}
        </CategoryList>
      </Category>
    ) : <></>;
  };

  const subCategoriesFilters = () => {
    const subCategories = getProductSubCategories(filters);
    return subCategories && subCategories.length && subCategories.length > 0 ? (
      <Category>
        SubCategoria
        <CategoryList>
          {subCategories.map((sc) => (
            <CategoryItem key={sc.label}>
              <NavLink to={`/products/${encodeURIComponent(routeFilters ? `${routeFilters}-${sc.value}` : sc.value)}`}>
                {sc.label}
              </NavLink>
            </CategoryItem>
          ))}
        </CategoryList>
      </Category>
    ) : <></>;
  };

  return (
    <Root>
      {!hideFilters ? (
        <>
          <Filters>
            {(!filters || !filters.sector) && sectorFilters()}
            {(!filters || !filters.line) && lineFilters()}
            {(!filters || !filters.category) && categoriesFilters()}
            {(!filters || !filters.subCategory) && subCategoriesFilters()}
          </Filters>
          <MobileFilters onClick={() => setMobileFilter(true)}>
            <BiFilterAlt />
            Filtrar
          </MobileFilters>
          <MobileNavBase active={mobileFilter} onClick={() => setMobileFilter(false)} />
          <MobileBase active={mobileFilter}>
            <MobileNavSide>
              <ModalTitle>
                Filtros
                <IoMdClose title="fechar filtros" onClick={() => setMobileFilter(false)} />
              </ModalTitle>
              <ModalContent>
                {(!filters || !filters.sector) && sectorFilters()}
                {(!filters || !filters.line) && lineFilters()}
                {(!filters || !filters.category) && categoriesFilters()}
                {(!filters || !filters.subCategory) && subCategoriesFilters()}
              </ModalContent>
            </MobileNavSide>
          </MobileBase>
        </>
      ) : <></>}
    </Root>
  );
}

SideBar.propTypes = {
  filters: PropTypes.shape({
    sector: PropTypes.string,
    line: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
    term: PropTypes.string,
  }),
  hideFilters: PropTypes.bool,
  routeFilters: PropTypes.string
};

SideBar.defaultProps = {
  filters: {},
  hideFilters: false,
  routeFilters: undefined
};

export default SideBar;
