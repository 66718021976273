import React from 'react';

import PropTypes from 'prop-types';

import { ModalContainer } from './styles';

export function Modal({ children, open }) {
  return open && (
    <ModalContainer>
      {children}
    </ModalContainer>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  open: PropTypes.bool,

};

Modal.defaultProps = {
  children: undefined,
  open: false,
};
