import React, { cloneElement } from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { Root, HeaderBase, FooterBase, CenterBase } from 'layouts/LandingPage/styles';
import PropTypes from 'prop-types';

function LandingPage({ active, children, ...props }) {
  return (
    <Root>
      <HeaderBase active={active}>
        <Header />
      </HeaderBase>

      <CenterBase>
        {cloneElement(children, { ...props })}
      </CenterBase>

      <FooterBase>
        <Footer />
      </FooterBase>
    </Root>
  );
}

LandingPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  active: PropTypes.bool
};

LandingPage.defaultProps = {
  children: undefined,
  active: false
};

export default LandingPage;
