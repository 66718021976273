import styled from 'styled-components';

export const InputComponent = styled.div`
  scroll-margin: 4rem;
`;

export const InputError = styled.span`
  color: red;
  font-size: 0.8rem;
`;
