import React from 'react';

import PropTypes from 'prop-types';

import { Root, InputStyle, Label } from './styles';

function InputText({ id, label, ...rest }) {
  return (
    <Root>
      {label && (<Label htmlFor={id}>{label}</Label>)}
      <InputStyle id={id} {...rest} />
    </Root>
  );
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

InputText.defaultProps = {
  label: undefined,
};

export default InputText;
