import React, { cloneElement } from 'react';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';

function PageRouter({ children, Layout, ...props }) {
  return (
    <Route
      {...props}
      component={(innerProps) =>
        Layout ? (
          <Layout {...innerProps}>
            {children}
          </Layout>
        ) : cloneElement(children, { ...innerProps })}
    />
  );
}

PageRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  Layout: PropTypes.elementType,
};

PageRouter.defaultProps = {
  children: undefined,
  Layout: undefined
};

export default PageRouter;
