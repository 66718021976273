import React from 'react';
import { Switch } from 'react-router-dom';

import LandingPage from 'layouts/LandingPage';
import StoreLandingPage from 'layouts/StoreLandingPage';
import AboutUs from 'pages/AboutUs';
import Catalog from 'pages/Catalog';
import Contact from 'pages/Contact';
import Home from 'pages/Home';
import Products from 'pages/Products';
import ProductDetails from 'pages/Products/Details';
import PageRouter from 'routes/PageRouter';

function Routes() {
  return (
    <Switch>
      <PageRouter path="/" exact Layout={LandingPage}>
        <Home />
      </PageRouter>

      <PageRouter path="/contact" exact Layout={LandingPage}>
        <Contact />
      </PageRouter>

      <PageRouter path="/products/:routeFilter?" exact Layout={StoreLandingPage}>
        <Products />
      </PageRouter>

      <PageRouter path="/products/details/:id" exact Layout={StoreLandingPage}>
        <ProductDetails hideFilters />
      </PageRouter>

      <PageRouter path="/catalog" exact Layout={LandingPage}>
        <Catalog />
      </PageRouter>

      <PageRouter path="/about-us" exact Layout={LandingPage}>
        <AboutUs />
      </PageRouter>
    </Switch>
  );
}

Routes.propTypes = {
};

Routes.defaultProps = {
};

export default Routes;
