import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Root } from 'components/Form/styles';
import PropTypes from 'prop-types';
import { recursiveModifyChildren } from 'utils/children';

function Form({ children, onSubmit, ...rest }) {
  const fieldsRef = useRef({});

  const [newChildren, setNewChildren] = useState(children);

  const isInput = useCallback((child) => child && child.props ? !!child.props.fieldId : false, []);

  const interceptInput = useCallback((child) => {
    const fieldId = child && child.props && child.props.fieldId ? child.props.fieldId : '';
    return {
      ref: (newRef) => {
        if (fieldsRef && fieldsRef.current) fieldsRef.current[fieldId] = newRef;
        return newRef;
      }
    };
  }, []);

  const validateFields = useCallback(() => {
    const fields = ((fieldsRef && fieldsRef.current
      && Object.values(fieldsRef.current)) || []).map((f) => f).reverse();
    const fieldErors = (fields || []).filter((f) => !f.validate());
    return !fieldErors || !fieldErors.length || fieldErors.length <= 0;
  }, []);

  const getFieldsValues = useCallback(() => {
    const fieldValues = {};
    ((fieldsRef && fieldsRef.current && Object.entries(fieldsRef.current)) || [])
      .forEach(([k, v]) => {
        fieldValues[k] = v.value;
      });
    return fieldValues;
  }, []);

  const resetFieldsValues = useCallback(() => {
    ((fieldsRef && fieldsRef.current && Object.values(fieldsRef.current)) || [])
      .forEach((f) => {
        f.setValue(undefined);
      });
  }, []);

  const handleOnSubmit = useCallback((event) => {
    event.preventDefault();
    if (validateFields()) {
      return onSubmit ? onSubmit(event, getFieldsValues()) : undefined;
    }
    return undefined;
  }, [onSubmit, validateFields, getFieldsValues]);

  useEffect(() => {
    setNewChildren(recursiveModifyChildren(children, isInput, interceptInput));
  }, [children, isInput, interceptInput]);

  return (
    <Root
      {...rest}
      noValidate
      onSubmit={handleOnSubmit}
      onReset={() => resetFieldsValues()}
    >
      {newChildren}
    </Root>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  children: undefined,
  onSubmit: () => { }
};

export default Form;
