import { getNameByPath } from './string';

export function getImageVariant(images, variant) {
  return (images || []).find((image) => {
    const imageName = getNameByPath(image.path);
    if (variant && variant === imageName) {
      return true;
    }
    return false;
  });
}
