import styled from 'styled-components';

export const StoreBase = styled.div`
  width: var(--master-content-width);
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: 1247px) {
    width: 100%;
    padding: 1rem 0.5rem;
  }
`;

export const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.hideFilters ? 'column' : 'row'};

  @media (max-width: 690px) {
    flex-direction: column;
  } 
`;

export const SideBarBase = styled.div`
  display: ${(props) => props.hideFilters ? 'none' : 'block'};
  width: ${(props) => props.hideFilters ? '100%' : '20rem'};
  margin-bottom: ${(props) => props.hideFilters ? '-1rem' : '0'};

  @media (max-width: 890px) {
    width: ${(props) => props.hideFilters ? '100%' : '16rem'};
  }

  @media (max-width: 690px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
`;
