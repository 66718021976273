export function getUrlQueryParam(url, param) {
  const paramsMap = getUrlParams(url);
  return paramsMap[param] || undefined;
}

function getUrlParams(url) {
  const paramsString = (url.split('?') || [])[1];
  if (paramsString) {
    const params = (paramsString.split('&') || []);
    const paramsMap = {};
    params.forEach((p) => {
      const param = (p.split('=') || []);
      const paramKey = param[0];
      const paramValue = param[1];

      paramsMap[paramKey] = paramValue;
    });
    return paramsMap;
  }
  return {};
}
