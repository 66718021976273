import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { MdShoppingCart } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button/styles';
import Form from 'components/Form';
import Input from 'components/Input';
import InputText from 'components/InputText';
import Textarea from 'components/Textarea';
import { Detail, DetOne, DetThree } from 'pages/Products/Details/styles';
import { Span } from 'pages/Products/styles';
import PropTypes from 'prop-types';
import { sendContactEmail } from 'services/email';
import { getProductImgUrl } from 'services/products';
import { getImageVariant } from 'utils/image';
import { getLocalProducts, qtdUpdateLocalProduct, removeAllLocalProducts, removeLocalProduct, } from 'utils/localStorage';
import { validateEmail, validatePhone, removeMask } from 'utils/validations';

import {
  Root, Center, Card, CardHeader, ButtonBase, ProductDetail,
  ProductImg, MoreProductsCart, SelectedProducts, ProductCount,
  ProductCountButton, MoreProduct, InputProductCount, RemoveProduct,
  ProductContent, ProductCountAndRemove, Message, ProductContainer
} from './styles';

function Contact({ history }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactResponse, setContactResponse] = useState(false);

  const sendContact = useCallback(async (event, formData) => {
    const contact = { ...formData, phone: removeMask(formData.phone) };
    if (products && products.length && products.length > 0) {
      const interestProducts = products.map((p) => {
        let prodMessage = `- ${p.name} Cod:${p.id} Quantidade:${p.qtd}`;
        (Object.entries(p.selectedVariations) || []).forEach(([key, val]) => { prodMessage = `${prodMessage} ${key}:${val}`; });
        return prodMessage;
      }).join('\n');
      contact.message = `${contact.message}\n\nProdutos Selecionados\n${interestProducts}`;
    }
    const response = await sendContactEmail(contact, '[Contato/Orçamento]', setLoading);
    if (response) {
      if (event && event.target && event.target.reset) {
        event.target.reset();
      }
      setProducts([]);
      removeAllLocalProducts();
    }
    setContactResponse(response ?
      { msg: 'Contato/orçamento enviado com sucesso.', type: 'success' } :
      { msg: 'Desculpe, problema ao enviar seu contato/orçamento, tente novamente.', type: 'error' });
  }, [products]);

  const loadProducts = useCallback(() => {
    const localProducts = getLocalProducts() || {};
    delete localProducts.storageDate;
    setProducts(Object.values(localProducts));
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    if (contactResponse && contactResponse.msg) {
      const timer = setTimeout(() => setContactResponse(undefined), 6000);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => { };
  }, [contactResponse]);

  function handleQtdUpdate(productId, qtd) {
    qtdUpdateLocalProduct(productId, qtd);
    loadProducts();
  }

  function handleRemoveProduct(product) {
    removeLocalProduct(product);
    loadProducts();
  }

  function handleQtdIncrement(product, qtd) {
    const newQtd = qtd + 1;
    qtdUpdateLocalProduct(product, newQtd);
    loadProducts();
  }

  function handleQtdDecrement(product, qtd) {
    const newQtd = qtd - 1;
    qtdUpdateLocalProduct(product, newQtd);
    loadProducts();
  }

  function getProductImage(product) {
    let imageUrl = product.images[0].path;
    Object.values(product.selectedVariations || {}).forEach((psv) => {
      const image = getImageVariant(product.images, psv);
      if (image) {
        imageUrl = image.path;
      }
    });
    return getProductImgUrl(imageUrl);
  }

  return (
    <Root>
      <Center>
        <Form onSubmit={sendContact}>
          <CardHeader>Contato e orçamentos</CardHeader>
          <Card>
            <Input
              fieldId="name"
              required
              requiredMessage="Nome é obrigatório"
            >
              <InputText id="name" type="text" label="Nome" />
            </Input>
            <Input
              fieldId="email"
              required
              requiredMessage="E-mail é obrigatório"
              validation={(v) => validateEmail(v) ? undefined : 'E-mail inválido'}
            >
              <InputText id="email" type="email" label="E-mail" />
            </Input>
            <Input
              fieldId="phone"
              required
              requiredMessage="Telefone é obrigatório"
              validation={(v) => validatePhone(v) ? undefined : 'Telefone inválido'}
            >
              <InputMask id="phone" mask="(99) 99999-9999" maskChar="_">
                {(inputProps) => <InputText {...inputProps} id="phone" type="phone" label="Telefone" />}
              </InputMask>
            </Input>
            <Input
              fieldId="message"
              required
              requiredMessage="Mensagem é obrigatório"
            >
              <Textarea id="message" rows="5" label="Mensagem" />
            </Input>
            {products && products.length && products.length > 0 ? (
              <ProductContainer>
                <SelectedProducts>Produtos Selecionados</SelectedProducts>
                {products.map((product) => (
                  <ProductContent key={product.id}>
                    <ProductDetail>
                      {product && product.images ? (
                        <Link to={`/products/details/${product.id}`}>
                          <ProductImg
                            src={getProductImage(product)}
                            alt={product.name}
                          />
                        </Link>
                      ) : <></>}
                      <Detail>
                        <DetOne>
                          <Link to={`/products/details/${product.id}`}>
                            {product.name}
                          </Link>
                        </DetOne>
                        {/* <DescTwo>
                          <Span>CA</Span>
                          {product.id}
                        </DescTwo> */}
                        {product.selectedVariations && (
                          Object.entries(product.selectedVariations).map(([key, val]) => (
                            <DetThree>
                              <Span>{key}</Span>
                              {val}
                            </DetThree>
                          )))}
                      </Detail>
                    </ProductDetail>

                    <ProductCountAndRemove>
                      <ProductCount>
                        <ProductCountButton
                          onClick={() => handleQtdDecrement(product, product.qtd)}
                          disabled={product.qtd <= 1}
                          type="button"
                        >
                          <AiOutlineMinus />
                        </ProductCountButton>

                        <InputProductCount
                          type="number"
                          value={product.qtd || ''}
                          onChange={(e) => handleQtdUpdate(product, Number(e.target.value))}
                        />

                        <ProductCountButton
                          onClick={() => handleQtdIncrement(product, product.qtd)}
                          type="button"
                        >
                          <AiOutlinePlus />
                        </ProductCountButton>
                      </ProductCount>

                      <RemoveProduct
                        onClick={() => handleRemoveProduct(product)}
                        type="button"
                        title="Remover produto"
                      >
                        <FaTrash />
                      </RemoveProduct>
                    </ProductCountAndRemove>
                  </ProductContent>
                ))}
                <MoreProduct>
                  <MoreProductsCart onClick={() => history.push('/products')}>
                    + Adicionar mais produtos
                    <MdShoppingCart />
                  </MoreProductsCart>
                </MoreProduct>
              </ProductContainer>
            ) : <></>}
          </Card>
          <ButtonBase>
            {contactResponse && contactResponse.msg && contactResponse.type ?
              <Message success={contactResponse.type === 'success'}>{contactResponse.msg}</Message>
              : <></>}
            <Button disabled={loading} type="submit">Enviar</Button>
          </ButtonBase>
        </Form>
      </Center>
    </Root>
  );
}

Contact.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
};

Contact.defaultProps = {
  history: undefined,
};

export default Contact;
