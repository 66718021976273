import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { recursiveModifyChildren } from 'utils/children';
import { focusRef } from 'utils/ref';

import { InputComponent, InputError } from './styles';

const Input = forwardRef(({
  children, fieldId, required, requiredMessage, validation
}, ref) => {
  const fieldRef = useRef();

  const [newChildren, setNewChildren] = useState(children);
  const [fieldError, setFieldError] = useState(undefined);
  const [value, setValue] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      let errorMessage;
      if (required && !value) {
        errorMessage = requiredMessage;
      }
      if (!errorMessage && validation) {
        errorMessage = validation(value);
      }
      setFieldError(errorMessage);
      if (errorMessage) {
        focusRef(fieldRef && fieldRef.current);
      }
      return !errorMessage;
    },
    value,
    setValue: (v) => handleOnChange(v)
  }));

  const handleOnChange = useCallback((e) => {
    if (e && e.target) {
      setValue(e.target.value || '');
    } else {
      setValue(e || '');
    }
  }, []);

  const isInput = useCallback((child) => {
    if (child && child.props && child.props.id && child.props.id === fieldId) {
      return true;
    }
    return false;
  }, [fieldId]);

  const interceptInput = useCallback((child) => {
    const oldOnChange = child && child.props && child.props.onChange ?
      child.props.onChange : () => { };
    return ({
      value,
      textareaValue: value,
      onChange: (e) => { handleOnChange(e); oldOnChange(e); },
    });
  }, [value, handleOnChange]);

  useEffect(() => {
    setNewChildren(recursiveModifyChildren(children, isInput, interceptInput));
  }, [children, isInput, interceptInput]);

  return (
    <InputComponent ref={fieldRef} error={fieldError}>
      <InputError>{fieldError}</InputError>
      {newChildren}
    </InputComponent>
  );
});

Input.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  fieldId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  validation: PropTypes.func
};

Input.defaultProps = {
  required: false,
  requiredMessage: 'Campo obrigatório',
  validation: undefined
};

export default Input;
