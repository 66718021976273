import React from 'react';

import { Root, InputTextArea, Label } from 'components/Textarea/styles';
import PropTypes from 'prop-types';

function Textarea({ id, label, ...rest }) {
  return (
    <Root>
      {label && (<Label htmlFor={id}>{label}</Label>)}
      <InputTextArea id={id} {...rest} />
    </Root>
  );
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string
};

Textarea.defaultProps = {
  label: undefined
};

export default Textarea;
