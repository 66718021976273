import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { BreadCrumbsContent, Divisor, BreadCrumbFilter } from './styles';

function BreadCrumbs({ breadCrumbs }) {
  return breadCrumbs && breadCrumbs.length > 0 && (
    <BreadCrumbsContent>
      {breadCrumbs.map((bc, index) => (index === (breadCrumbs.length - 1)
        ? (
          <span key={bc.label}>
            {bc.label}
          </span>
        ) : index === 0 ?
          (
            <BreadCrumbFilter key={bc.label}>
              <NavLink to={bc.to}>{bc.label}</NavLink>
              <Divisor>
                {' | '}
              </Divisor>
            </BreadCrumbFilter>
          ) : (
            <BreadCrumbFilter key={bc.label}>
              <NavLink to={bc.to}>{bc.label}</NavLink>
              <Divisor>
                {' > '}
              </Divisor>
            </BreadCrumbFilter>
          )
      ))}
    </BreadCrumbsContent>
  );
}

BreadCrumbs.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string
    })
  )
};

BreadCrumbs.defaultProps = {
  breadCrumbs: [],
};

export default BreadCrumbs;
