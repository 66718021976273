import axios from 'axios';

const emailApi = axios.create({ baseURL: process.env.REACT_APP_EMAIL_API_BASE_PATH });
const emailOrganization = process.env.REACT_APP_EMAIL_API_ORGANIZATION || '';
const emailProperty = process.env.REACT_APP_EMAIL_API_PROPERTY || '';

function checkSuccessResponse(response) {
  return response && response.status && response.status >= 200 && response.status <= 299;
}

export async function sendContactEmail(contact, subject, setLoading = () => { }) {
  let result;
  const request = {
    organization: emailOrganization,
    property: emailProperty,
    prospectName: contact.name || '',
    prospectEmail: contact.email || '',
    prospectPhone: contact.phone || '',
    subject: subject || '',
    text: contact.message || ''
  };
  try {
    setLoading(true);
    const response = await emailApi.post('/contact-us', request);
    result = checkSuccessResponse(response);
  } catch (err) {
    result = false;
  } finally {
    setLoading(false);
  }
  return result;
}
